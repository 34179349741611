/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Spin } from 'antd';

// import Head from '../components/Head';
// import StandardPage from '../components/StandardPage';
// import CTASection from '../components/CTASection';
// import LearnMore from '../components/LearnMore';

// const { Link } = Anchor;

const LetterSection = () => {
  // const anchorStyle = { visibility: 'hidden', cursor: 'none' };
  if (typeof window !== 'undefined') window.location = '/essays/dpc-solves-the-healthcare-crisis';
  return (
    <div className="fullpage row jc ac">
      <Spin />
    </div>
  );
};

export default LetterSection;
